import React from 'react';
import {
  StyledFooter,
  FooterInfo,
  Address,
  PoweredBy,
  Copyright
} from './styled';

const Footer = ({ exhibition, noPadding, assetsPath }) => {
    const { footer_text, footer_phone, footer_credits } = exhibition;
    const texts = footer_text ? footer_text.split(';') : [];
    const phones = footer_phone ? footer_phone.split(';') : [];

    return (
        <StyledFooter noPadding={noPadding}>
            <FooterInfo>
                <img src={`${assetsPath}/logo.svg`} alt="Fabienne Lévy"/>
                    <Address>
                        {texts.map((text) => (
                        <pre key={text}>
                          {text.trim()}
                        </pre>
                        ))}
                    </Address>
                <Address>
                {phones.map((phone) => (
                    <pre key={phone}>
                      {phone ? `Tel. ${phone.trim()}` : null}
                    </pre>
                ))}
                <p>{footer_credits}</p>
                </Address>
                <PoweredBy>
                    <span>Powered by</span>
                    <a href="https://arteia.com/">
                      <img src={`${assetsPath}/logo_arteia.svg`} alt="Powered by Arteia"/>
                    </a>
                </PoweredBy>
            </FooterInfo>
            <Copyright>
                <p>&copy; {new Date().getFullYear()} Fabienne Levy</p>
            </Copyright>
        </StyledFooter>
    );
};

export default Footer;
